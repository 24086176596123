<template>
    <painel :titulo="titulo" icone="pi pi-user" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-3 lg:col-2">
                <label class="required">Data de Nascimento</label>
                <Calendar
                    v-model="dataNascimento"
                    :monthNavigator="true"
                    :yearNavigator="true"
                    :class="{ 'p-error': v$.dataNascimento.$error }"
                    @date-select="v$.dataNascimento.$touch()"
                    :manualInput="false"
                />
                <small class="p-error" v-if="v$.dataNascimento.$error">
                    <span v-if="v$.dataNascimento.required.$invalid">A data de nascimento é obrigatória</span>
                    <span v-if="v$.dataNascimento.dataValida.$invalid">Forneça uma data válida</span></small
                >
            </div>
            <div class="field col-12 md:col-6 lg:col-7">
                <label class="required">Email</label>
                <InputText type="text" v-model="email" :class="{ 'p-error': v$.email.$error }" @input="v$.email.$touch()" />
                <small class="p-error" v-if="v$.email.$error">O email é obrigatório</small>
            </div>
            <div class="field col-12 md:col-3">
                <label class="required">Telefone</label>
                <InputMask v-model="telefone" mask="(99)999999999" :unmask="true" @input="v$.telefone.$touch()" :class="{ 'p-error': v$.telefone.$error }" />
                <small class="p-error" v-if="v$.telefone.$error">O telefone é obrigatório</small>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar()" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import ParticipantesServices from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        participante: {
            type: Object,
        },

        titulo: {
            type: String,
        },
    },

    emits: ['atualizar'],

    data() {
        return {
            dataNascimento: null,
            email: null,
            telefone: null,
            erros: [],
        };
    },

    validations() {
        return {
            dataNascimento: {
                required,
                dataValida: () => {
                    return this.$moment(this.dataNascimento, 'DD/MM/YYYY').isSameOrBefore(new Date(), 'day');
                },
            },
            email: { required, email },
            telefone: { required },
        };
    },

    created() {
        this.preencher();
    },

    methods: {
        preencher() {
            this.dataNascimento = new Date(this.participante?.dataNascimento);
            this.email = this.participante?.email;
            this.telefone = this.participante?.telefone;
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja atualizar o participante?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let participanteDto = {
                participanteId: this.participante.participanteId,
                dataNascimento: this.$moment(this.dataNascimento).format('YYYY-MM-DD'),
                email: this.email,
                telefone: this.telefone,
            };
            this.$store.dispatch('addRequest');
            ParticipantesServices.atualizar(this.$route.params.id, participanteDto)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Participante',
                            detail: 'Participante atualizado com sucesso',
                            life: 3000,
                        });
                        this.atualizar();
                        this.cancelar();
                    } else {
                        this.erros = response.errors;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        atualizar() {
            this.$emit('atualizar');
        },

        cancelar() {
            this.$router.push({
                name: `Checkins_Eventos_Participantes_Detalhar`,
                params: {
                    participanteId: this.participante.participanteId,
                },
            });
        },
    },

    watch: {
        participante() {
            if (this.participante) {
                this.preencher();
            }
        },
    },
};
</script>
